import { atom } from "jotai";
import {
  componentOptions,
  type ComponentName,
  type ComponentState,
} from "app/componentOptions";
import componentStackAtom from "./componentStackAtom";
import dayjs from "dayjs";

const pushComponentToStack = atom<
  null,
  /**
   * From Jotai docs:
   * The second type parameter describes the arguments (plural) you will pass to the "write" function.
   * Even if you only plan to have one argument, this type must be an array.
   */
  Array<ComponentState<ComponentName>>,
  void
>(null, (get, set, { componentType, componentProps, uiProps = {} }) => {
  // Use mobileStackIndex as the unique key if provided, else fallback to a timestamp
  const uniqueKey = dayjs().toDate().getTime().toString();

  const newComponent = atom({
    componentType,
    componentProps,
    uiProps: {
      ...componentOptions[componentType].defaultUiOptions,
      ...uiProps,
      uniqueKey,
    },
  });
  const stack = get(componentStackAtom);
  if (uiProps.mobileStackIndex !== undefined) {
    set(componentStackAtom, [
      ...stack.slice(0, uiProps.mobileStackIndex),
      newComponent,
    ]);
  } else {
    set(componentStackAtom, [...stack, newComponent]);
  }
});
export default pushComponentToStack;
