import { Classes } from "@blueprintjs/core";
import { useIsMobileOrTablet } from "app/jm";

export const Skeleton = ({ lines }: { lines?: number }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  return (
    <div className="d-flex flex-col flex-1 gap-2">
      {Array.from({ length: lines || 1 }).map((_, i) => (
        <div
          key={i}
          className={`${Classes.SKELETON} ${isMobileOrTablet && "text-xl"}`}
        >
          placeholder
        </div>
      ))}
    </div>
  );
};
