import { GraphQLClient, type RequestMiddleware } from "graphql-request";
import { app } from "app/Firebase";
import {
  type TypedDocumentNode,
  type VariablesOf,
} from "@graphql-typed-document-node/core";
import {
  useQuery,
  useQueryClient,
  useMutation,
  type UseQueryOptions,
  UseMutationOptions,
  QueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { getAuth } from "firebase/auth";
import { type IServerSideGetRowsRequest } from "ag-grid-community";
import { deepmerge } from "deepmerge-ts";
import { assert } from "lib/util/assert";
import { Kind } from "graphql";
import Toaster from "app/Toaster";

// ResultOf lets you grab the type of the result of a document being queried
export {
  type ResultOf,
  type VariablesOf,
} from "@graphql-typed-document-node/core";

const auth = getAuth(app);

const endpoint =
  window.JM_ENVIRONMENT === "TESTING"
    ? "http://localhost/api/graphql" // Because otherwise during tests we get "Error: only absolute urls are supported"
    : "api/graphql";

const requestMiddleware: RequestMiddleware = async (request) => {
  const token = await auth.currentUser?.getIdToken();
  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getQueryKey = <TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables
) => {
  const def = document.definitions[0];
  assert(def && def.kind === Kind.OPERATION_DEFINITION && def.name?.value);
  return [def.name.value, variables];
};

const handleErrors = async (response: Response) => {
  if (!response.ok) {
    const errorData = await response.json();
    if (response.status === 403) {
      Toaster.error("You do not have permission to access this resource.");
      console.error("Access denied: ", errorData);
    }
    throw new Error(errorData.message || "An error occurred");
  }
  return response;
};

// I think we should prefer using useFetchJmApiQuery wherever
// possible, as this utilises useQuery to support caching etc.
// TODO: Consider marking as deprecated? Or use eslint to issue a warning?
export const jmApiClient = new GraphQLClient(endpoint, {
  requestMiddleware,
  fetch: async (input, init) => {
    const response = await fetch(input, init);
    return handleErrors(response);
  },
});

// From https://the-guild.dev/graphql/codegen/docs/guides/react-vue#appendix-i-react-query-with-a-custom-fetcher-setup
//TODO: We should set variables to {} by default, so that we get the same query key if variables are omitted
// Note: TData is transformed data if we are using the "select" option.
// @deprecated use gqty instead
export function useJmApiQuery<TResult, TVariables, TData = TResult>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  options?: Omit<UseQueryOptions<TResult, unknown, TData>, "queryKey">
) {
  return useQuery({
    queryKey: getQueryKey(document, variables),
    queryFn: async () => jmApiClient.request(document, variables || undefined),
    ...options,
  });
}

export function useJmApiMutation<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  options?: UseMutationOptions<TResult, unknown, TVariables>
) {
  return useMutation({
    mutationFn: async (variables: TVariables) =>
      jmApiClient.request(document, variables || undefined),
    ...options,
  });
}

export function fetchJmApiQuery<TResult, TVariables>(
  queryClient: QueryClient,
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  options?: Omit<
    UseQueryOptions<TResult, unknown, TResult>,
    "queryKey" | "queryFn"
  >
) {
  return queryClient.fetchQuery({
    queryKey: getQueryKey(document, variables),
    queryFn: async () => jmApiClient.request(document, variables ?? undefined),
    ...options,
  });
}

// Inferred from https://the-guild.dev/graphql/codegen/docs/guides/react-vue#appendix-i-react-query-with-a-custom-fetcher-setup
//TODO: We should set variables to {} by default, so that we get the same query key if variables are omitted
export function useFetchJmApiQuery() {
  const queryClient = useQueryClient();
  const fetchQuery = useCallback(
    <TResult, TVariables>(
      document: TypedDocumentNode<TResult, TVariables>,
      variables?: TVariables,
      options?: UseQueryOptions<TResult, unknown, TResult>
    ) => {
      return queryClient.fetchQuery({
        queryKey: getQueryKey(document, variables),
        queryFn: async () =>
          jmApiClient.request(document, variables ?? undefined),
        ...options,
      });
    },
    [queryClient]
  );

  return fetchQuery;
}

export function useInvalidateJmApiQueries() {
  const queryClient = useQueryClient();
  return useCallback(
    <TResult, TVariables>(
      document: TypedDocumentNode<TResult, TVariables>,
      variables?: TVariables
    ) => {
      void queryClient.invalidateQueries({
        queryKey: getQueryKey(document, variables),
      });
    },
    [queryClient]
  );
}

export function variablesFromAgGridRequest(request: IServerSideGetRowsRequest) {
  console.log("request", request);
  const variables = {
    options: {
      sort: request.sortModel.map(({ colId, sort }) => ({
        field: colId,
        dir: sort.toUpperCase(),
      })),
      offset: request.startRow,
      limit: request.endRow !== undefined ? request.endRow - 1 : 50,
    },
  };

  return variables;
}

// This pattern of defaulting to void forces the user to provide a generic type,
// otherwise the first item of the array is inferred as the type
export function mergeVariables<T extends TypedDocumentNode | void = void>(
  ...merge: VariablesOf<T>[]
): VariablesOf<T> {
  return deepmerge(...merge) as VariablesOf<T>;
}
